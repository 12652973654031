<template>
  <div
    v-if="isSearchable"
    class="d-flex align-items-center justify-content-start"
  >
    <autosuggest
      ref="autocomplete"
      v-model="searchQuery"
      class="l-table-suggestion w-75"
      :suggestions="suggestions"
      :input-props="getSearchInputProps()"
      :table-suggestion="isSuggestionTable"
      :style="autoSuggestStyle"
      :table-suggestion-configs="tableSuggestionConfigs"
      v-bind="getSuggestProps()"
      @input="fetchResults"
      @keyup.enter="reloadData"
      @selected="selectHandler"
    />
    <b-button
      class="searchButton"
      variant="success"
      @click="reloadData"
    >
      <feather-icon
        icon="LSearchIcon"
        size="16"
      />
    </b-button>
  </div>
</template>






<script>

import { BButton } from 'bootstrap-vue'
import Autosuggest from '@/views/components/AutoSuggest/Autosuggest.vue'

function debounce(func, timeout) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => func(...args), timeout)
  }
}

export default {
  name: 'AutoSuggestComponent',
  components: { BButton, Autosuggest },
  data() {
    return {
      suggestions: [],
      searchQuery: null,
    }
  },
  props: {
    isSearchable: {
      type: Boolean,
      default: false,
    },
    isSuggestionTable: {
      type: Boolean,
      default: () => false,
    },
    autoSuggestStyle: {
      type: Object,
      default: () => {},
    },
    tableSuggestionConfigs: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    fetchResults(searchQuery) {
      this.search(searchQuery, this)
    },
    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 50),

    searchQueryClear() {
      this.searchQuery = ''
      this.reloadData()
    },
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'table-search',
        placeholder: 'Search',
        class: 'form-control',
        name: 'table-search',
      }
      return {
        ...defaultInputProps,
        ...this.searchInputProps,
      }
    },
    getSuggestProps() {
      const defaultProps = {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
      return {
        ...defaultProps,
        ...this.searchProps,
      }
    },
    reloadData() {
      this.filterToggle = false
      this.refetchData()
    },
    selectHandler(suggest) {
      this.$emit('searchSelected', suggest, this)
      if (!this.isReloadOnSelectSuggestItem) return
      this.reloadData()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/l-table-custom.scss';
</style>

