<template>
  <div
    class="d-flex"
    style="gap: 8px"
  >
    <button-dashed
      class="btn-custom btn-custom--clear"
      @click="removeFilter()"
    >
      <feather-icon
        icon="LClearIcon"
        size="16"
      />
    </button-dashed>
    <b-button
      v-for="(status, idx) in statusFilterTab"
      :key="idx"
      :class="`btn-custom btn-custom--${status.icon} ${activeIndexes[idx] ? 'btn-custom--active' : ''}`"
      style="border: none"
      @click="toggleActive(idx)"
    >
      <feather-icon
        :icon="status.icon"
        size="18"
        style="fill: #646464"
      />
    </b-button>
  </div>
</template>

<script>
// Components
import { BButton } from 'bootstrap-vue'
// Configs
import config from '@/views/main/warehouse/main-order/config'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'

export default {
  name: 'StatusFilterTab',
  components: {
    ButtonDashed,
    BButton,
  },
  data() {
    return {
      activeIndexes: [],
    }
  },
  created() {
    this.$store.dispatch('warehouse-orders/setGlobalVariable', [])
  },
  methods: {
    removeFilter() {
      this.activeIndexes = []
      this.$store.dispatch('warehouse-orders/setGlobalVariable', [])
    },
    toggleActive(idx) {
      this.$set(this.activeIndexes, idx, !this.activeIndexes[idx])
      if (this.activeIndexes.every(item => item === false)) {
        this.removeFilter()
      }
      this.$store.dispatch('warehouse-orders/setGlobalVariable', this.activeIndexes)
    },
  },
  setup() {
    const { statusFilterTab } = config()

    return {
      statusFilterTab,
    }
  },
}
</script>
<style lang="scss" scoped>
.btn-custom {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #D9D9D9 !important;
  margin: 0;
  transition: all 0.2s ease;

  &--active {
    background-color: #646464 !important;
    transition: all 0.2s ease;
    & svg {
      fill: #fff !important;
    }
  }

  &--LPickIcon, &--LBoxIcon {
    & svg {
      position: relative;
      top: 3px;
    }
  }

  &--clear {
    background: transparent !important;
    & svg {
      fill: #D9D9D9;
    }

    &:hover {
      border-color: rgba(100, 100, 100, 0.8);
      & svg {
        fill: rgba(100, 100, 100, 0.8);
      }
    }
  }
}
</style>
<style lang="scss">
.paddingCustom {
  border: 1px dashed #dde3e7;
  border-radius: 0.35rem;
  height: 36px;
  min-width: 115px;

  & .btn {
    padding: 7px 15.5px !important;
  }

  .dropdown-item {
    padding: 0.2rem 1.28rem;
  }
}

.dropNewClass{
  outline: none;
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  color: #49454f;
  gap: 9px;
}
</style>
