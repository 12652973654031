<template>
  <div>
    <!--    <button-->
    <!--      type="btn-custom"-->
    <!--      @click="navigateToPack"-->
    <!--    >-->
    <!--      {{ 'Pack' }}-->
    <!--    </button>-->
    <portal to="body-top">
      <div>
        <h3 class="text-center font-weight-bolder mb-2">
          {{ getWarehousePageTitle }} {{ getWarehousePageTitle === 'Fulfillment Orders' ? getCurrentWeek : '' }}
        </h3>
      </div>
    </portal>
    <div>
      <page-top :is-searchable="true" />
    </div>
    <router-view />
  </div>
</template>
<script>
import config from '../config'
import PageTop from '../components/view-top/PageTop.vue'

export default {
  components: { PageTop },
  computed: {
    getCurrentWeek() {
      const curr = new Date() // get current date
      const first = curr.getDate() - curr.getDay() + 1 // First day is the day of the month - the day of the week
      const last = first + 6 // last day is the first day + 6

      return `${new Date(curr.setDate(first)).toLocaleDateString('en-us', { month: 'short' })}. ${new Date(curr.setDate(first)).toLocaleDateString('en-us', { day: 'numeric' })} — ${new Date(curr.setDate(last)).toLocaleDateString('en-us', { month: 'short' })}. ${new Date(curr.setDate(last)).toLocaleDateString('en-us', { day: 'numeric' })}`
    },
    getWarehousePageTitle() {
      return this.$store.state[this.MODULE_NAME].warehousePageTitle
    },
  },
  created() {
    this.$store.dispatch(`${this.MODULE_NAME}/list`).then(res => {
      const { data } = res.data
      console.log(data)

      this.$store.commit(`${this.MODULE_NAME}/GET`, data)
    })
  },
  methods: {
    navigateToPack() {
      this.$router.push({ name: 'home-warehouse-pack' })
    },
  },
  setup() {
    const { FULFILLMENT_ORDERS, MODULE_NAME } = config()

    return {
      MODULE_NAME,
      FULFILLMENT_ORDERS,
    }
  },
}

</script>
